#content-vote {
  cursor: pointer;
  width: 260px;
  height: 35px;
  display: flex;
  align-items: center;
  transition: all ease 0.5s;
  margin-top: 10px;
  margin-bottom: 10px;
}

#content-vote-responsive {
  cursor: pointer;
  width: 260px;
  height: 40px;
  display: flex;
  align-items: center;
  transition: all ease 0.5s;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 5px;
}

#behavior-text-reponsive {
  padding-left: 10px;
}
.positive, .neutral, .negative {
  margin: 1px;
  box-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
  border: 1px solid rgb(200, 200, 200);
  border-radius: 10px;
  background-color: #fff;
  margin: 20px auto 20px auto;
  width: 90%;
}

.positive:hover, .neutral:hover, .negative:hover {
  background-color: #9696967e;
}

.positive.clicked, .neutral.clicked, .negative.clicked {
  background-color: #fff;
  background-size: 7%;
  background-position: right;
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-image: url("/assets/tick.png"); 
}

.positive.clicked {
  border: 1px solid #01b48d;
}

.neutral.clicked {
  border: 1px solid #f7ae04;
}

.negative.clicked {
  border: 1px solid #ec1102;
}

#content-vote img {
  height: 32px;
  width: 32px;
  margin-left: 5px;
  margin-right: 10px;
}

#checkboxstyle {
  justify-content: right;
}
