#behavior-list{
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
  height: 400px;
  align-content: space-between;
  position: center;
  text-align: center;
  justify-content: center;
  padding-bottom: 10px;
}

.first-behavior-headline {
  height: 30px;
  display: flex;
  align-items: center;
  transition: all ease 0.2s;
  margin-bottom: 2px;
  padding-left: 17px;
  text-align: center;
  align-content: center;
  justify-content: center;
  font-size: 90%;
  color: rgb(184, 184, 184)
}

.behavior-headline {
  height: 30px;
  display: flex;
  align-items: center;
  transition: all ease 0.2s;
  margin-bottom: 2px;
  padding-left: 17px;
  text-align: center;
  align-content: center;
  justify-content: center;
  font-size: 90%;
  color: rgb(184, 184, 184)
}

.box-flat-white {
  margin: 1px;
  width: 90%;
}

#hrStyle{
  height: 0.5px;
  width: 280px;
  margin: auto;
  padding-top: 0px;
  margin-bottom: 0px;
  align-items: center;
  text-align: center;
  align-content: center;
  border-width: 1px 0px 0px 0px;
}


#hrStyle-responsive {
  height: 0.5px;
  width: 90%;
  margin-top: auto;
  padding-top: 3px;
  margin-bottom: 0px;
  align-items: center;
  text-align: center;
  align-content: center;
  border-width: 1px 0px 0px 0px;
}


#smiley {
  width: 25px;
}



