body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#behavior-list{
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
  height: 400px;
  align-content: space-between;
  position: center;
  text-align: center;
  justify-content: center;
  padding-bottom: 10px;
}

.first-behavior-headline {
  height: 30px;
  display: flex;
  align-items: center;
  transition: all ease 0.2s;
  margin-bottom: 2px;
  padding-left: 17px;
  text-align: center;
  align-content: center;
  justify-content: center;
  font-size: 90%;
  color: rgb(184, 184, 184)
}

.behavior-headline {
  height: 30px;
  display: flex;
  align-items: center;
  transition: all ease 0.2s;
  margin-bottom: 2px;
  padding-left: 17px;
  text-align: center;
  align-content: center;
  justify-content: center;
  font-size: 90%;
  color: rgb(184, 184, 184)
}

.box-flat-white {
  margin: 1px;
  width: 90%;
}

#hrStyle{
  height: 0.5px;
  width: 280px;
  margin: auto;
  padding-top: 0px;
  margin-bottom: 0px;
  align-items: center;
  text-align: center;
  align-content: center;
  border-width: 1px 0px 0px 0px;
}


#hrStyle-responsive {
  height: 0.5px;
  width: 90%;
  margin-top: auto;
  padding-top: 3px;
  margin-bottom: 0px;
  align-items: center;
  text-align: center;
  align-content: center;
  border-width: 1px 0px 0px 0px;
}


#smiley {
  width: 25px;
}




#content-vote {
  cursor: pointer;
  width: 260px;
  height: 35px;
  display: flex;
  align-items: center;
  transition: all ease 0.5s;
  margin-top: 10px;
  margin-bottom: 10px;
}

#content-vote-responsive {
  cursor: pointer;
  width: 260px;
  height: 40px;
  display: flex;
  align-items: center;
  transition: all ease 0.5s;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 5px;
}

#behavior-text-reponsive {
  padding-left: 10px;
}
.positive, .neutral, .negative {
  margin: 1px;
  box-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
  border: 1px solid rgb(200, 200, 200);
  border-radius: 10px;
  background-color: #fff;
  margin: 20px auto 20px auto;
  width: 90%;
}

.positive:hover, .neutral:hover, .negative:hover {
  background-color: #9696967e;
}

.positive.clicked, .neutral.clicked, .negative.clicked {
  background-color: #fff;
  background-size: 7%;
  background-position: right;
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-image: url("/assets/tick.png"); 
}

.positive.clicked {
  border: 1px solid #01b48d;
}

.neutral.clicked {
  border: 1px solid #f7ae04;
}

.negative.clicked {
  border: 1px solid #ec1102;
}

#content-vote img {
  height: 32px;
  width: 32px;
  margin-left: 5px;
  margin-right: 10px;
}

#checkboxstyle {
  justify-content: right;
}


.__floater__arrow {
  margin-left:32%;
} 

.__floater__body {
  margin-left: 30px;
  margin-right: 20px;
}

.__floater > span:first {
  position: 'absolute';
  top: -100px;
}

#productchoice {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    justify-content: space-between;
    display: flex;        
    flex-direction: column;
}

#headline{
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
}

#evolution_icon {
    width: 50px;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
 
}

#feedback360 {    
    opacity: 0.8;
    padding-top: 20px;
    margin-top: 20px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all .2s ease-in-out; 
}

#feedback360:hover {
    background-image: url("/assets/productchoice/plus.png");
    background-repeat: no-repeat;
    background-position: top;
    background-size: 30px;
    opacity: 1;
    padding-top: 20px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    -webkit-transform: scale(1.1);
            transform: scale(1.1); 
}


#analytics {
    opacity: 0.8;
    margin-top: 50px;
    padding-top: 20px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all .2s ease-in-out; 
}

.active-product p.ms-font-l {
    color: green;
    font-weight: 800 ;
}

#analytics:hover {
    opacity: 1;
    background-image: url("/assets/productchoice/plus.png");
    background-repeat: no-repeat;
    background-position: top;
    background-size: 30px;
    margin-top: 50px;
    padding-top: 20px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    -webkit-transform: scale(1.1);
            transform: scale(1.1); 
}

#analyticsImage {
    opacity: 1;
    background-image: url("/assets/productchoice/plus.png");
    background-repeat: no-repeat;
    background-position: top;
    background-size: 30px;
    margin-top: 50px;
    padding-top: 20px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    -webkit-transform: scale(1.1);
            transform: scale(1.1); 
}


#deconnection {
    opacity: 0.8;
    margin-top: 50px;
    padding-top: 20px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all .2s ease-in-out; 
}


#deconnection:hover {
    opacity: 1;
    background-image: url("/assets/productchoice/plus.png");
    background-repeat: no-repeat;
    background-position: top;
    background-size: 30px;
    margin-top: 50px;
    padding-top: 20px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    -webkit-transform: scale(1.1);
            transform: scale(1.1); 
}

#arrow{
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    flex: 0 0 30px;
}

#arrowBottom{
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    flex: 0 0 30px;
}

#stay-in-place {
    height: 100%;
    width: 100%;
    background: red;
    position: absolute;
}

#move-in-to-place {
    position: absolute;
    bottom: -100%;
    height : 100%;
    width : 100%;
    background: blue;
    opacity:0;
}
.box {
    position: relative;
    width:200px;
    height:200px;
    overflow:hidden;
}

.box:hover #move-in-to-place {
    bottom: 0;
    transition: all 1s, -webkit-transform 1s;
    transition: all 1s, transform 1s;
    transition: all 1s, transform 1s, -webkit-transform 1s;
    width:100%;
    height:100%;
    opacity:1;
}

p {
    font-family: sans-serif;
    font-size: 14px;
}
#dayspicker {
    text-align: center;
    margin-left: 5px;
}

#days {
    display: flex;
    margin-right: 10px;
    justify-content: space-around
}

#day {
    text-align: center;
    margin-left: 5px;
}

#checkboxes {
    display: flex;
    margin-right: 10px;
    justify-content: space-around;
}

.ms-Checkbox {
    background-color: white !important;
}

#smartdeconnextion {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 0px;
    background: #fff;
    position: fixed;
    top: 0px;
    margin-left: 0%;
    margin-right: 0%;
    padding-top: 10px;
    bottom: 0;
    overflow: scroll;
    width: 100%;
}

#smart-dnx-headline {
    margin-bottom: 30px;
}

.countrytimezone {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-right: 35px;
    padding-left: 35px;
    text-align: center;    
}

.countrytimezone.back {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right: 15px;
    padding-left: 15px;
    text-align: center;
    
}

.countrytimezone.clickedCountry {
    display:none;    
    text-align: center;
    bottom: -100%;
    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 2s;
}

.daysandhours {
    opacity: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* padding-top: 25px; */
    padding-right: 10px;
    padding-left: 10px;
    text-align: center;
}


.daysandhours.back {
    opacity: 1;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
    padding-left: 10px;
    text-align: center;
}

.daysandhours.clickedDays {
    display:none;    
    text-align: center;
    bottom: -100%;
    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 2s;
}

.enable {
    opacity: 1; 
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.enable.clickedEnable {
    display:none;    
    align-items: center;
    justify-content: center;
    flex-direction: column;
    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 2s;
}

#arrowBack{
    width: 30px;
    cursor: pointer;  
}

#arrowContinue{
    width: 30px;
    cursor: pointer;  
    margin-left: 20px;
}

#buttonSave {
    width: 30px;
    margin-top: 0px;
    padding-top:10px;
    padding-bottom:10px;
    padding-right:103px;
    padding-left:30px;
    margin-left: 20px;
    text-align: center;
    cursor: pointer;  
    border-radius: 5px;
    border-color: #0078d4;
    background-color: #0078d4;
    font-size: 12px;
    color: white;
}

#arrowProgress {
    position: fixed;
    bottom: 20px;
    left: 30px;
    right: 30px;
}

#content-header {
    background: #fff;
    color: #C55A11;
    height: 40px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    width: 100%;
}

#content-header-reponsive{
    background: #fff;
    color: #C55A11;
    margin: 0px;
    height: 50px;
    vertical-align: middle;
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
}

#content-main {
    background: #fff;
    position: fixed;
    top: 50px;
    margin-left: 0%;
    margin-right: 0%;
    padding-top: 10px;
    bottom: 0;
    overflow: scroll;
    width: 100%;
}

#content-main-responsive {
    background: #fff;
    position: fixed;
    top: 50px;
    bottom: 2px;
    margin-left: 0%;
    margin-right: 0%;
    padding-top: 10px;
    padding-bottom: 40px;
    overflow: scroll;
    width: 100%;
}

#magiclink{
    cursor: pointer;
    width: 30px;
    height: 30px;
    padding-right: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    flex: 0 0 30px; 
}

#magiclink:hover {
    -webkit-transform: scale(1.4);
            transform: scale(1.4); 
}

#magiclink-responsive{
    cursor: pointer;
    width: 30px;
    height: 30px;
    padding-right: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    flex: 0 0 30px;  
} 

#settingslink{
    cursor: pointer;
    width: 30px;
    height: 30px;
    padding-left: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s; 
    flex: 0 0 30px; 
    padding-right: 15px;
}

#settingslink:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

#settingslink-responsive {
    cursor: pointer;
    width: 30px;
    height: 30px;
    padding-left: 5px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s; 
    flex: 0 0 30px; 
    padding-right: 15px;
}

#smart-dxn-toggle {
    padding-left: 40px;
}

.onboarding-dashboard {
    padding: 0px;
}

h1 {
    margin: 0 0 0 0px;
    position: fixed;
    padding: 0px;
}

#toggle {
    flex: 1 1 auto;
    padding-left: 10px;
}
#nofeedbackcss {
  position: absolute;
  align-content: center;
  align-items: center;
  text-align: center;
  margin-top: 50px;
  margin-left: 10px;
  margin-right: 10px;
}

#nofeedback {
  margin-top: 40px;
  width: 120px;
}

#content-header {
    background: #fff;
    color: #C55A11;
    height: 40px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    width: 100%;
}

#content-header-reponsive{
    background: #fff;
    color: #C55A11;
    margin: 0px;
    height: 50px;
    vertical-align: middle;
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
}

#content-main {
    background: #fff;
    position: fixed;
    top: 60px;
    margin-left: 0%;
    margin-right: 0%;
    padding-top: 10px;
    bottom: 0;
    overflow: scroll;
    width: 100%;
}

#content-main-responsive {
    background: #fff;
    position: fixed;
    top: 60px;
    bottom: 2px;
    margin-left: 0%;
    margin-right: 0%;
    padding-top: 10px;
    padding-bottom: 40px;
    overflow: scroll;
    width: 100%;
}

#magiclinkFeedbackNotAllowed{
    cursor: pointer;
    width: 30px;
    height: 30px;
    padding-right: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    flex: 0 0 30px; 
}

#magiclinkFeedbackNotAllowed:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

#magiclinkFeedbackNotAllowed-responsive{
    cursor: pointer;
    width: 30px;
    height: 30px;
    padding-right: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    flex: 0 0 30px;  
} 

#settingslink{
    cursor: pointer;
    width: 30px;
    height: 30px;
    padding-left: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s; 
    flex: 0 0 30px; 
    padding-right: 15px;
}

#settingslink:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

#settingslink-responsive {
    cursor: pointer;
    width: 30px;
    height: 30px;
    padding-left: 5px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s; 
    flex: 0 0 30px; 
    padding-right: 15px;
}

h1 {
    margin: 0 0 0 0px;
    position: fixed;
    padding: 0px;
}
