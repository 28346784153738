#productchoice {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    justify-content: space-between;
    display: flex;        
    flex-direction: column;
}

#headline{
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
}

#evolution_icon {
    width: 50px;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
 
}

#feedback360 {    
    opacity: 0.8;
    padding-top: 20px;
    margin-top: 20px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all .2s ease-in-out; 
}

#feedback360:hover {
    background-image: url("/assets/productchoice/plus.png");
    background-repeat: no-repeat;
    background-position: top;
    background-size: 30px;
    opacity: 1;
    padding-top: 20px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transform: scale(1.1); 
}


#analytics {
    opacity: 0.8;
    margin-top: 50px;
    padding-top: 20px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all .2s ease-in-out; 
}

.active-product p.ms-font-l {
    color: green;
    font-weight: 800 ;
}

#analytics:hover {
    opacity: 1;
    background-image: url("/assets/productchoice/plus.png");
    background-repeat: no-repeat;
    background-position: top;
    background-size: 30px;
    margin-top: 50px;
    padding-top: 20px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transform: scale(1.1); 
}

#analyticsImage {
    opacity: 1;
    background-image: url("/assets/productchoice/plus.png");
    background-repeat: no-repeat;
    background-position: top;
    background-size: 30px;
    margin-top: 50px;
    padding-top: 20px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transform: scale(1.1); 
}


#deconnection {
    opacity: 0.8;
    margin-top: 50px;
    padding-top: 20px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all .2s ease-in-out; 
}


#deconnection:hover {
    opacity: 1;
    background-image: url("/assets/productchoice/plus.png");
    background-repeat: no-repeat;
    background-position: top;
    background-size: 30px;
    margin-top: 50px;
    padding-top: 20px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transform: scale(1.1); 
}

#arrow{
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    flex: 0 0 30px;
}

#arrowBottom{
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    flex: 0 0 30px;
}

#stay-in-place {
    height: 100%;
    width: 100%;
    background: red;
    position: absolute;
}

#move-in-to-place {
    position: absolute;
    bottom: -100%;
    height : 100%;
    width : 100%;
    background: blue;
    opacity:0;
}
.box {
    position: relative;
    width:200px;
    height:200px;
    overflow:hidden;
}

.box:hover #move-in-to-place {
    bottom: 0;
    -webkit-transition: all 1s, -webkit-transform 1s;
    transition: all 1s, transform 1s;
    width:100%;
    height:100%;
    opacity:1;
}

p {
    font-family: sans-serif;
    font-size: 14px;
}