#dayspicker {
    text-align: center;
    margin-left: 5px;
}

#days {
    display: flex;
    margin-right: 10px;
    justify-content: space-around
}

#day {
    text-align: center;
    margin-left: 5px;
}

#checkboxes {
    display: flex;
    margin-right: 10px;
    justify-content: space-around;
}

.ms-Checkbox {
    background-color: white !important;
}
