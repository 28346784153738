#smartdeconnextion {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 0px;
    background: #fff;
    position: fixed;
    top: 0px;
    margin-left: 0%;
    margin-right: 0%;
    padding-top: 10px;
    bottom: 0;
    overflow: scroll;
    width: 100%;
}

#smart-dnx-headline {
    margin-bottom: 30px;
}

.countrytimezone {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-right: 35px;
    padding-left: 35px;
    text-align: center;    
}

.countrytimezone.back {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right: 15px;
    padding-left: 15px;
    text-align: center;
    
}

.countrytimezone.clickedCountry {
    display:none;    
    text-align: center;
    bottom: -100%;
    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 2s;
}

.daysandhours {
    opacity: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* padding-top: 25px; */
    padding-right: 10px;
    padding-left: 10px;
    text-align: center;
}


.daysandhours.back {
    opacity: 1;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
    padding-left: 10px;
    text-align: center;
}

.daysandhours.clickedDays {
    display:none;    
    text-align: center;
    bottom: -100%;
    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 2s;
}

.enable {
    opacity: 1; 
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.enable.clickedEnable {
    display:none;    
    align-items: center;
    justify-content: center;
    flex-direction: column;
    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 2s;
}

#arrowBack{
    width: 30px;
    cursor: pointer;  
}

#arrowContinue{
    width: 30px;
    cursor: pointer;  
    margin-left: 20px;
}

#buttonSave {
    width: 30px;
    margin-top: 0px;
    padding-top:10px;
    padding-bottom:10px;
    padding-right:103px;
    padding-left:30px;
    margin-left: 20px;
    text-align: center;
    cursor: pointer;  
    border-radius: 5px;
    border-color: #0078d4;
    background-color: #0078d4;
    font-size: 12px;
    color: white;
}

#arrowProgress {
    position: fixed;
    bottom: 20px;
    left: 30px;
    right: 30px;
}
