
.__floater__arrow {
  margin-left:32%;
} 

.__floater__body {
  margin-left: 30px;
  margin-right: 20px;
}

.__floater > span:first {
  position: 'absolute';
  top: -100px;
}
